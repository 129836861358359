import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BubbleChart from "./pages/BubbleChart";
import Dashboard from "./pages/Dashboard";
import WatchlistChart from "./pages/WatchlistChart";
import Logout from "./pages/Logout";
import "./styles/Layout.css";
import { UserContext } from "./Context/UserContext"; // Context for managing user data

function App() {
  const [user, setUser] = useState(() => {
    // Load user data from local storage
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<BubbleChart />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/watchlist" element={<WatchlistChart />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </div>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
