import React from "react";
import "../styles/GeneralFooter.css";

const GeneralFooter = () => {
  return (
    <footer className="general-footer">
      <div className="footer-container">
        <p>© 2024 stockgalaxy.in All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default GeneralFooter;
